import React, { useRef, useState } from 'react'
import { MrModal } from '@hixme/modal'
import { connect } from 'react-redux'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { Input } from '@hixme-ui/forms'
import RadioButton from '@hixme-ui/radio-button'
import Button from '@hixme-ui/button'
import { getEmployee } from 'store/modules/persons/selectors'
import PlusIcon from './plus.svg'
import notification from 'modules/notification-manager'
import { getMedicareEnrollment } from 'store/modules/user-session/selectors'

// project
import { actions as cartActions } from 'store/modules/cart'
import { GET_MEDICARE_COVERAGE } from 'apollo/queries'
import { useQuery } from '@apollo/client'
import { useMedicareDocumentsUpload } from './useMedicareDocumentsUpload'

const PlanBlock = ({
  type,
  planType,
  premium,
  handlePlanTypeChange,
  handlePremiumChange,
  cleanupPlan,
}) => {
  return (
    <div
      style={{
        margin: '20px 0',
        borderTop: '1px solid #fafafa',
        borderColor: '#acacac',
        padding: '10px 0',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Text style={{ fontWeight: '600' }}>Plan type {+type + 1}</Text>
        {+type !== 0 && (
          <Button
            style={{
              backgroundColor: 'inherit',
              width: '36px',
              margin: '0 0 10px auto',
              color: 'black',
            }}
            onClick={() => cleanupPlan(type)}
          >
            Remove
          </Button>
        )}
      </div>
      <Container flex style={{ padding: '5px 0', marginBottom: '10px' }}>
        {['Plan A', 'Plan B', 'Plan C', 'Plan D', 'Supplemental'].map((plan, index) => {
          return (
            <RadioButton
              key={`${type}-${plan}`}
              style={{ width: index === 4 ? '120px' : '80px', marginRight: '10px' }}
              name={`${type}-${plan}`}
              text={plan}
              value={plan}
              onChange={(e) => {
                handlePlanTypeChange(e)
              }}
              checked={planType === plan}
            />
          )
        })}
      </Container>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Text style={{ fontWeight: '600' }}>Premium (monthly)</Text>
        <Input
          style={{ width: '135px' }}
          placeholder="$000.00"
          name="Premium"
          onChange={handlePremiumChange}
          value={premium}
          maxLength={9}
        />
        <Text style={{ color: '#acacac' }}>
          This is how much your medicare costs monthly. Some bills cost over three months.
        </Text>
      </div>
    </div>
  )
}

const MedicareModal = ({ confirm, confirmUpload, employee, medicareEnrollment }) => {
  const { data } = useQuery(GET_MEDICARE_COVERAGE, {
    variables: {
      employeePublicKey: employee.EmployeePublicKey,
    },
  })

  const { generateFileUrl, uploadFile } = useMedicareDocumentsUpload(employee.EmployeePublicKey)
  const [uploading, setUploading] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState(data?.getMedicareCoverage?.[0]?.documents)
  const [error, setError] = useState(null)
  const inputFile = useRef(null)
  const [plans, setPlans] = useState(
    data?.getMedicareCoverage?.[0]?.medicareCoverage?.map((item) => {
      return {
        planType: item.planType,
        premium: item.premium,
      }
    }) ?? []
  )

  const handleUpload = async (event) => {
    const file = event.target.files[0]
    if (!file) {
      setError('Please select a file to upload.')
      return
    }
    setUploading(true)
    setError(null)

    try {
      const {
        data: { generateMedicareCoveragePreSignedUrl },
      } = await generateFileUrl({
        variables: {
          input: {
            personPublicKey: employee.EmployeePublicKey,
            extension: file.name.split('.').pop(), // Get file extension
          },
        },
      })

      await uploadFile(generateMedicareCoveragePreSignedUrl.preSignedUrl, file)
      setUploadedFiles([...uploadedFiles, generateMedicareCoveragePreSignedUrl.fileKey])
      confirmUpload()
      setUploading(false)
    } catch (err) {
      setError('Failed to upload file.')
      setUploading(false)
    }
  }

  const handlePlanTypeChange = (index) => (e) => {
    const newPlans = [...plans]
    newPlans[index].planType = e.target.value
    setPlans(newPlans)
  }

  const handlePremiumChange = (index) => (e) => {
    const newPlans = [...plans]
    newPlans[index].premium = e.target.value
    setPlans(newPlans)
  }
  const cleanupPlan = (index) => {
    const newPlans = plans.splice(index, 1)
    setPlans(newPlans)
  }

  const addPlan = () => {
    setPlans([
      ...plans,
      {
        planType: '',
        premium: '',
      },
    ])
  }

  const handleMedicareNumberChange = (e) => {
    setMedicareNumber(e.target.value)
  }

  const [medicareNumber, setMedicareNumber] = useState(
    data?.getMedicareCoverage?.[0]?.medicareNumber || ''
  )

  return (
    <MrModal small hideCloseButton>
      <Container flexColumn style={{ position: 'relative' }}>
        {data?.getMedicareCoverage?.[0]?.medicareCoverage?.coverageDate && (
          <>
            <Text
              style={{
                position: 'absolute',
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '28px',
                letterSpacing: '0.01em',
                textAlign: 'left',
                top: '-9px',
              }}
            >
              Medicare
            </Text>
            <Text
              style={{
                backgroundColor: '#FCE9E9',
                padding: '10px 15px',
                margin: '30px 0 10px 0',
                borderRadius: '10px',
                color: '#ba8a8a',
              }}
            >
              Medicare needs to be complete by{' '}
              {data?.getMedicareCoverage?.[0]?.medicareCoverage?.coverageDate} or there could be
              significant penalties. Link to penalties?
            </Text>
          </>
        )}
        <Text
          style={{
            backgroundColor: '#E8F1FF',
            color: '#6e8ab2',
            padding: '10px 15px',
            margin: '30px 0 10px 0',
            borderRadius: '10px',
          }}
        >
          Selecting for employee {employee.FirstName} {employee.LastName}
        </Text>
        <Text style={{ color: '#acacac' }}>
          Learn more about Medicare with the SureCo Walkthrough{' '}
          <span style={{ color: '#5b9fff' }}>video</span>
        </Text>
        <Text style={{ margin: '10px 0 10px 0', fontWeight: '600' }}>Medicare number</Text>
        <Input
          style={{ width: '135px' }}
          placeholder="0000-000-0000"
          name="Medicare number"
          onChange={handleMedicareNumberChange}
          value={medicareNumber}
          maxLength={12}
        />
        <Text style={{ color: '#acacac' }}>
          This can be found on your account on{' '}
          <span style={{ color: '#5b9fff' }}>medicare.gov</span> or on your Medicare Premium Bill.{' '}
          <span style={{ color: '#5b9fff' }}>Learn more</span>
        </Text>

        {plans?.map((plan, index) => {
          return (
            <PlanBlock
              type={index}
              key={index}
              planType={plan.planType}
              premium={plan.premium}
              cleanupPlan={cleanupPlan}
              handlePlanTypeChange={handlePlanTypeChange(index)}
              handlePremiumChange={handlePremiumChange(index)}
            />
          )
        })}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            style={{
              alignItems: 'center',
              backgroundColor: '#f5f5f5',
              width: '120px',
              margin: '10px 0',
              color: 'black',
            }}
            onClick={addPlan}
          >
            <PlusIcon style={{ marginRight: '10px' }} />
            Add Plan
          </Button>
          <Text style={{ color: '#acacac', marginLeft: 'auto' }}>
            Monthly total:{' '}
            {plans?.reduce((prev, arg) => {
              return +prev + parseFloat(arg.premium || 0)
            }, 0)}
          </Text>
        </div>

        <Text style={{ fontWeight: '600' }}>Upload verification document</Text>
        <Text style={{ color: '#acacac', marginBottom: '10px' }}>
          A photo or scan of your Medicare Premium Bill.{' '}
          <span style={{ color: '#5b9fff' }}>See sample documents</span>
        </Text>
        <input style={{ display: 'none' }} ref={inputFile} type="file" onChange={handleUpload} />
        <Button
          onClick={() => inputFile.current.click()}
          disabled={uploading}
          style={{
            backgroundColor: '#f5f5f5',
            width: '64px',
            margin: '0 auto 10px 0',
            color: 'black',
          }}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {uploadedFiles.map((file, index) => {
            return (
              <Text style={{ color: '#acacac', marginBottom: '10px' }} key={index}>
                {file}
              </Text>
            )
          })}
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <Button
          disabled={confirming}
          style={{
            backgroundColor: '#1677FF',
            color: 'white',
            width: '64px',
            margin: '0 0 10px auto',
          }}
          onClick={() => {
            setConfirming(true)
            confirm({
              enrollmentPublicKey: medicareEnrollment.EnrollmentPublicKey,
              personPublicKey: employee.Id,
              medicareNumber: medicareNumber,
              medicareCoverage: plans,
              documents: uploadedFiles,
            })
          }}
        >
          Confirm
        </Button>
      </Container>
    </MrModal>
  )
}

const mapStateToProps = (state) => {
  const employee = getEmployee(state)
  const medicareEnrollment = getMedicareEnrollment(state)

  return {
    employee,
    medicareEnrollment,
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirm: async (confirmData) => {
    dispatch(cartActions.UpdateMedicare(confirmData))
  },
  confirmUpload: async () => {
    dispatch(
      notification.actions.createNotification({
        type: 'success',
        autoClose: true,
        message: `File was successfully uploaded`,
      })
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MedicareModal)
