import { capitalize } from 'lodash'

class GroupPerson {
  constructor({
    Id,
    FirstName,
    LastName,
    DateOfBirth,
    EffectiveAge,
    Relationship,
    MedicareEligible,
    isPlanSelected,
  } = {}) {
    this.personPublicKey = Id
    this.firstName = FirstName
    this.lastName = LastName
    this.fullName = `${FirstName} ${LastName}`
    this.dateOfBirth = DateOfBirth
    this.effectiveAge = EffectiveAge
    this.relationship = Relationship || 'EMPLOYEE'
    this.isMedicareEligible = MedicareEligible
    this.isPlanSelected = isPlanSelected
  }

  getTitle() {
    return this.isMedicare
      ? 'Family member(s) Medicare eligible'
      : `Family member(s) in ${this.state} (${this.ratingAreaString})`
  }

  getSubtitle() {
    return capitalize(this.name)
  }

  isEmployee() {
    return this.relationship === 'EMPLOYEE'
  }

  clone() {
    return new GroupPerson({
      ...this,
    })
  }
}

export default GroupPerson
