import { gql } from '@apollo/client'

const CORE_GROUP_FIELDS = gql`
  fragment CoreGroupFields on FamilyGroups {
    enrollmentPublicKey
    benefitEffectiveDate
    isCustomGroups
    familyGroups {
      id
      groupType
      groupName
      stateProvince
      ratingArea
      medicareEligible
      selectedPlanName
      familyMembers {
        id
        firstName
        lastName
        dateOfBirth
        effectiveAge
        medicareEligible
        relationship
        stateProvince
        ratingArea
      }
      dateCreated
      updatedOn
    }
    declinedFamilyMembers {
      id
      firstName
      lastName
      dateOfBirth
      effectiveAge
      medicareEligible
      relationship
      stateProvince
      ratingArea
    }
  }
`

export const GET_OR_CREATE_GROUPS = gql`
  mutation GetOrCreateFamilyGroups($input: GetOrCreateFamilyGroupsInput!) {
    getOrCreateFamilyGroups(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`

export const SPLIT_PERSON_FROM_GROUP = gql`
  mutation SplitPersonFromFamilyGroups($input: SplitPersonFromFamilyGroupInput!) {
    splitPersonFromFamilyGroup(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`

export const RESET_FAMILY_GROUPS = gql`
  mutation ResetFamilyGroups($input: ResetFamilyGroupsInput!) {
    resetFamilyGroups(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`

export const DECLINE_HEALTH_MEMBER = gql`
  mutation DeclineHealthForFamilyMember($input: DeclineHealthForFamilyMemberInput!) {
    declineHealthForFamilyMember(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`
export const UNDO_DECLINE_HEALTH = gql`
  mutation UndoDeclineHealthForFamilyMember($input: UndoDeclineHealthForFamilyMemberInput!) {
    undoDeclineHealthForFamilyMember(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`
export const DECLINE_MEDICARE = gql`
  mutation DeclineMedicareForFamilyMember($input: DeclineMedicareForFamilyMemberInput!) {
    declineMedicareForFamilyMember(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`

export const UNDO_DECLINE_MEDICARE = gql`
  mutation UndoDeclineMedicareForFamilyMember($input: UndoDeclineMedicareForFamilyMemberInput!) {
    undoDeclineMedicareForFamilyMember(input: $input) {
      ...CoreGroupFields
    }
  }
  ${CORE_GROUP_FIELDS}
`

export const DELETE_FAMILY_GROUPS = gql`
  mutation DeleteFamilyGroups($input: DeleteFamilyGroupsInput!) {
    deleteFamilyGroups(input: $input)
  }
`

export const GET_CLIENT = gql`
  query GetClient($getClientId: String!) {
    getClient(id: $getClientId) {
      companyName
      allowSplitFamily
      medicareContribution
      medicareShoppingAssistance
      employeeProfileReadOnly
      displayContributionsToEmployees
    }
  }
`

export const SEND_BENEFIT_ENROLLMENT_EVENT = gql`
  mutation EnrollMeBenefitEventHandler(
    $enrollMeBenefitEventHandlerInput: EnrollMeBenefitEventHandlerInput
  ) {
    enrollMeBenefitEventHandler(
      enrollMeBenefitEventHandlerInput: $enrollMeBenefitEventHandlerInput
    ) {
      code
      message
    }
  }
`

export const UPDATE_MEDICARE = gql`
  mutation UpdateMedicareCoverage($input: UpdateMedicareCoverageInput) {
    updateMedicareCoverage(input: $input) {
      employeePublicKey
      personPublicKey
      medicareNumber
      medicareCoverage {
        planType
        premium
      }
      documents
    }
  }
`

export const GET_MEDICARE_COVERAGE = gql`
  query GetMedicareCoverage($employeePublicKey: String) {
    getMedicareCoverage(employeePublicKey: $employeePublicKey) {
      employeePublicKey
      personPublicKey
      medicareNumber
      medicareCoverage {
        planType
        premium
      }
      documents
    }
  }
`

export const GENERATE_MEDICARE_PRESIGNED_URL = gql`
  mutation GenerateMedicareCoveragePreSignedUrl($input: GeneratePreSignedUrlMedicareInput) {
    generateMedicareCoveragePreSignedUrl(input: $input) {
      preSignedUrl
      fileKey
    }
  }
`

// Digital Mail
export const SEARCH_RECIPIENT_MAIL = gql`
  query SearchRecipientMail($input: SearchRecipientMailInput!) {
    searchRecipientMail(input: $input) {
      rows {
        id
        body
        daysRemainingToForward
        isRead
        mailType
        pdfUrl
        receivedDate
        recipientId
        recipientName
        relationship
        senderName
        subject
      }
      total
      offset
      limit
    }
  }
`

export const MARK_MAIL_AS_READ = gql`
  mutation MarkMailAsRead($input: MarkMailAsReadInput!) {
    markMailAsRead(input: $input) {
      mailMessageIds
    }
  }
`
export const MARK_MAIL_AS_UNREAD = gql`
  mutation MarkMailAsRead($input: MarkMailAsUnReadInput!) {
    markMailAsUnRead(input: $input) {
      mailMessageIds
    }
  }
`

export const GET_MAIL = gql`
  query GetMail($input: GetMailInput!) {
    getMail(input: $input) {
      id
      pdfUrl
    }
  }
`

export const GET_CLIENT_PAYROLL_PERIOD_FOR_USER = gql`
  query GetClientPayrollPeriodForUser($employeeId: String!) {
    getClientPayrollPeriodForUser(employeeId: $employeeId) {
      monthsOfCoverage
      payrollCycle
    }
  }
`

export const GET_ENROLLMENT_RENEWAL_ELIGIBILITY = gql`
  query GetEnrollmentRenewalEligibility($input: GetEnrollmentRenewalEligibilityInput!) {
    getEnrollmentRenewalEligibility(input: $input) {
      groupId
      updatedHealthPlanId
      previousHealthPlanId
    }
  }
`

export const IS_CHILD_UNDER_AGE_CAP = gql`
  query IsChildUnderAgeCap($input: IsChildUnderAgeCapInput!) {
    isChildUnderAgeCap(input: $input) {
      isUnderAgeCap
      effectiveAge
      benefitsEffectiveDate
      stateAgeCap {
        state
        ageCap
        benefitYear
      }
    }
  }
`
