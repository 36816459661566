import { createSelector } from 'reselect'
import { isEmpty as isEmptyValidator } from 'lodash'
import moment from 'moment'
import { getWaiter } from 'redux-waiter'

import BENEFIT_TYPES from '@hixme/benefit-types'
import { ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER } from '@hixme/role-policy'
import { selectors as authSelectors } from '@hixme/auth-ui'
import cart from 'store/modules/cart'
import guestUserIds from 'data/guestUserIds'

import { ADD_TO_MY_PROVIDERS, GET_USER_BENEFITS, NAME, REMOVE_FROM_MY_PROVIDERS } from './constants'
import { getCartSummary } from '../cart/selectors'

const {
  BENEFIT_TYPE_ACCIDENT,
  BENEFIT_TYPE_BASIC_LIFE,
  BENEFIT_TYPE_CHIROPRACTIC,
  BENEFIT_TYPE_CRITICAL_ILLNESS,
  BENEFIT_TYPE_DCFSA,
  BENEFIT_TYPE_DENTAL,
  BENEFIT_TYPE_EMPLOYEE_ASSISTANCE,
  BENEFIT_TYPE_FSA,
  BENEFIT_TYPE_FTA,
  BENEFIT_TYPE_GROUP_HEALTH,
  BENEFIT_TYPE_HEALTH,
  BENEFIT_TYPE_HOSPITAL_INDEMNITY,
  BENEFIT_TYPE_INTERESTED,
  BENEFIT_TYPE_LEGAL_PLAN,
  BENEFIT_TYPE_LEGAL_SHIELD,
  BENEFIT_TYPE_LONG_TERM_DISABILITY,
  BENEFIT_TYPE_LPFSA,
  BENEFIT_TYPE_SHORT_TERM_DISABILITY,
  BENEFIT_TYPE_VISION,
  BENEFIT_TYPE_VOLUNTARY_AD_D,
  BENEFIT_TYPE_VOLUNTARY_LIFE,
  BENEFIT_TYPE_VOLUNTARY_LIFE_SIMPLE,
  BENEFIT_TYPE_VOLUNTARY_LONG_TERM_DISABILITY,
  BENEFIT_TYPE_VOLUNTARY_SHORT_TERM_DISABILITY,
} = BENEFIT_TYPES

/* USER SESSION */
export const getSessionSelector = createSelector(
  (state) => state[NAME],
  (session) => session
)

export const sessionErrorsSelector = createSelector(getSessionSelector, ({ errors }) => errors)

export const sessionIsUpdating = createSelector(
  (state) => state[NAME],
  (session) => session.updatingUser
)

export const sessionStarted = createSelector(
  (state) => state[NAME],
  (session) => session.userInitialized
)

/* USER DATA */
export const getUser = createSelector(
  (state) => state[NAME],
  (session) => session.user
)

export const getUserPayroll = createSelector(
  (state) => state[NAME],
  (state) => state.payroll
)

export const getUserName = createSelector(
  (state) => state[NAME],
  (session) => {
    const { FirstName = '', LastName = '' } = session.user || {}
    return `${FirstName} ${LastName}`
  }
)

export const getUserId = createSelector(
  (state) => state[NAME],
  (session) => (session ? session.userId : undefined)
)

export const isGuestUser = createSelector(
  (state) => getUserId(state),
  (userId) => guestUserIds.includes(userId)
)

export const isImpersonating = createSelector(
  (state) => {
    const userId = getUserId(state)
    const loggedInUser = authSelectors.getCredentials(state)
    const isImpersonating =
      userId !== loggedInUser.personPublicKey && [ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER].indexOf(loggedInUser.userRole) > -1
    return isImpersonating
  },
  (isImpersonating) => isImpersonating
)

/* ENROLLMENT RESPONSES */
export const getEnrollmentResponses = createSelector(
  (state) => state[NAME],
  (userSession) => {
    const sorted = userSession.enrollmentResponses.sort(
      (a, b) => parseFloat(a.QuestionNumber) - parseFloat(b.QuestionNumber)
    )
    return sorted
  }
)

/* PEVIOUS ENROLLMENT */
export const getPreviousEnrollment = createSelector(
  (state) => state[NAME],
  (userSession) => userSession.previousEnrollment
)

/* ENROLLMENT SESSION */
export const getEnrollmentSession = createSelector(getSessionSelector, (userSession) => ({
  ...userSession.enrollmentSession,
  EnrollmentCutoffTime: userSession.enrollmentCutoffTime,
}))

export const getEnrollmentPublicKey = createSelector(getEnrollmentSession, (session) => session.Id)

export const getTermsAndConditionsAgreed = createSelector(
  getEnrollmentSession,
  (session) => session.TermsAndConditionsAgreed
)

export const isCartCommitted = createSelector(
  getEnrollmentSession,
  (session) => session.CartCommitted || false
)

export const getBundleGroups = createSelector(
  getEnrollmentSession,
  (session) => session.BundleGroups
)

export const getFamilyGroups = createSelector(
  getEnrollmentSession,
  (session) => session.FamilyGroups || {}
)

export const hasGroupToShop = createSelector(
  getEnrollmentSession,
  (session) =>
    session.FamilyGroups &&
    session.FamilyGroups.FamilyGroups &&
    session.FamilyGroups.FamilyGroups.filter((group) => group.GroupType !== 'MEDICARE').some(
      (group) => !group.SelectedPlanName
    )
)

export const getGroups = createSelector(
  getEnrollmentSession,
  (session) => session.FamilyGroups && session.FamilyGroups.FamilyGroups
)

export const getHealthGroups = createSelector(
  getEnrollmentSession,
  (session) =>
    session.FamilyGroups &&
    session.FamilyGroups.FamilyGroups.filter(
      (group) =>
        group.GroupType === 'HEALTH_INSURANCE' || group.GroupType === 'HEALTH_INSURANCE_SINGLE'
    )
)

export const getRecommendedBundleGroups = createSelector(
  getEnrollmentSession,
  (session) => session.HixmeRecommendedBundleGroups
)

export const getIsCustomGroups = createSelector(
  getEnrollmentSession,
  (session) => session.IsCustomGroups
)

export const getEnrollmentIsActiveSelector = createSelector(getEnrollmentSession, (session) => {
  const now = moment()
  const startDate = moment(session.EnrollmentStartDate)
  const endDate = moment(session.EnrollmentEndDate).hour(session.EnrollmentCutoffTime.split(':')[0])
  const enrollmentIsActive = now.isAfter(endDate) || now.isBefore(startDate, 'day')
  return enrollmentIsActive
})

export const isBenefitActive = createSelector(
  getEnrollmentSession,
  ({ BenefitEndDate, BenefitEffectiveDate }) => {
    const now = moment().format('YYYY-MM-DD')
    if (!BenefitEndDate || !BenefitEffectiveDate) {
      return false
    }
    const benefitEndDate = moment(BenefitEndDate, 'YYYY-MM-DD')
    const benefitStartDate = moment(BenefitEffectiveDate, 'YYYY-MM-DD')
    return moment(now).isBetween(benefitStartDate, benefitEndDate, null, '[]')
  }
)

export const getIntentToElectHsa = createSelector(
  getEnrollmentSession,
  (session) => session.IntentToElectHSA
)

export const isQleEnrollment = createSelector(
  getEnrollmentSession,
  (session) => !isEmptyValidator(session.QualifyingLifeEvent)
)

export const isEnrolling = createSelector(
  getEnrollmentIsActiveSelector,
  (state) => isCartCommitted(state),
  (EnrollmentIsActive, CartCommitted) => !EnrollmentIsActive && !CartCommitted
)

export const getMedicareEnrollment = createSelector(
  getEnrollmentSession,
  (enrollmentSession) => {
    return {
      EnrollmentPublicKey: enrollmentSession.Id,
      MedicareEnrollment: enrollmentSession.MedicareEnrollment,
      MedicareCoverage: enrollmentSession.Medicare,
    }
  }
)

export const allowMedicareEnrollmentExperience = createSelector(
  getMedicareEnrollment,
  (medicareEnrollment) => {
    return medicareEnrollment?.MedicareEnrollment?.Status === 'Open'
  }
)

/**
 * This is same as isEnrolling selector but excludes QLE enrolling
 */
export const isEnrollingExcludeQLE = createSelector(
  getEnrollmentIsActiveSelector,
  (state) => isCartCommitted(state),
  (state) => isQleEnrollment(state),
  (EnrollmentIsActive, CartCommitted, isQleEnrollment) =>
    !EnrollmentIsActive && !CartCommitted && !isQleEnrollment
)

export const isPreEnrollment = createSelector(getEnrollmentSession, (session) => {
  const enrollmentDate = moment(session.EnrollmentStartDate)
  const diff = moment().diff(enrollmentDate, 'seconds')
  return diff < 0
})

export const getEnrollmentStartDateSelector = createSelector(getEnrollmentSession, (session) =>
  moment(session.EnrollmentStartDate).format('MM/DD/YYYY')
)

export const getBenefitEffectiveDateSelector = createSelector(getEnrollmentSession, (session) =>
  moment(session.BenefitEffectiveDate).format('MM/DD/YYYY')
)

export const getBenefitEffectiveYearSelector = createSelector(getEnrollmentSession, (session) =>
  moment(session.BenefitEffectiveDate, 'YYYY/MM/DD').year()
)

export const getBenefitEndDateSelector = createSelector(getEnrollmentSession, (session) =>
  moment(session.BenefitEndDate).format('MM/DD/YYYY')
)

export const getEnrollmentEndDateSelector = createSelector(getEnrollmentSession, (session) => {
  return moment(session.EnrollmentEndDate)
    .hour(session.EnrollmentCutoffTime.split(':')[0])
    .format('MM/DD/YYYY')
})

export const getDaysUntilEnrollmentStartsSelector = createSelector(
  getEnrollmentSession,
  (session) => {
    const todaysDate = moment()
    const enrollmentStartDate = moment(session.EnrollmentStartDate)
    const diff = enrollmentStartDate.diff(todaysDate, 'days')
    return diff
  }
)

export const getTimeRemainingMessageSelector = createSelector(getEnrollmentSession, (session) => {
  const endDate = moment(session.EnrollmentEndDate).hour(session.EnrollmentCutoffTime.split(':')[0])
  const momentStr = moment(endDate).fromNow(true)
  const retMessage = `You have ${momentStr} to enroll or decline this insurance`
  return retMessage
})

export const getTimeRemainingFlexibleSpendingSelector = createSelector(
  getEnrollmentSession,
  (session) => {
    const benefitsEffectiveDate = moment(session.BenefitsEffectiveDate)
    const endOfYear = moment(benefitsEffectiveDate).endOf('year')

    const diff = endOfYear.to(benefitsEffectiveDate, true)
    const monthDiff = endOfYear.diff(benefitsEffectiveDate, 'months')
    if (monthDiff === 11) {
      return null
    }
    return `You have ${diff} remaining to fund this account via paycheck deductions.`
  }
)

export const getEnrollmentDurationMessageSelector = createSelector(
  getEnrollmentSession,
  (session) => {
    const info = {}
    const now = moment().format('YYYY-MM-DD HH:mm:ss')
    const startDate = moment(session.EnrollmentStartDate)
    const endDate = moment(session.EnrollmentEndDate).hour(
      session.EnrollmentCutoffTime.split(':')[0]
    )
    const enrollmentActive = moment(now).isBetween(startDate, endDate, null, '[]')
    // Removed `info.isEnrolling = enrollmentActive`
    // use `isEnrollingExcludeQLE` or `isEnrolling` selector for consistency
    // and select it separately for when needed

    if (enrollmentActive) {
      info.message = `Enrollment ends in ${endDate.toNow(true)}`
      info.shortMessage = endDate.toNow(true)
    } else {
      info.message = `Enrollment starts ${startDate.fromNow()}`
    }
    return info
  }
)

/* CLIENT SETTINGS */
export const getClientSettings = createSelector(
  getSessionSelector,
  (userSession) => userSession.clientSettings
)

export const getClientId = createSelector(getClientSettings, (settings) => settings.Id)

export const getCompanyName = createSelector(
  getClientSettings,
  (clientSettings) => clientSettings.CompanyName
)

export const getShowNewExperience = createSelector(
  getClientSettings,
  (clientSettings) => clientSettings.ShowNewExperience || false
)

export const getIsClientProspect = createSelector(
  getClientSettings,
  (settings) => settings.IsProspect
)

export const hasSeenProviderLookupQuestion = createSelector(
  getEnrollmentSession,
  (session) => session.ProviderSearchCheck
)

export const hasFamilyGroups = createSelector(
  getEnrollmentSession,
  (session) => !isEmptyValidator(session.FamilyGroups)
)

export const isCreatingOrUpdating = createSelector(
  (state) => state.waiter,
  (waiterStore = {}) => {
    const waitersKeys = Object.keys(waiterStore).filter(
      (waiterName) =>
        waiterName.includes(ADD_TO_MY_PROVIDERS) || waiterName.includes(REMOVE_FROM_MY_PROVIDERS)
    )
    const isEmpty = waitersKeys.length === 0
    if (isEmpty) return false

    return waitersKeys.some((key) => (waiterStore[key] || {}).isPending)
  }
)

export const getClientBenefitsSummary = createSelector(getClientSettings, (settings) => {
  const benefitCounts = settings.BenefitCounts

  const additionalBenefits = []
  const hasBenefits = benefitCounts.reduce((prev, benefit) => {
    if (benefit.BenefitType === BENEFIT_TYPE_DENTAL) {
      return { ...prev, hasDental: true }
    }
    if (benefit.BenefitType === BENEFIT_TYPE_VISION) {
      return { ...prev, hasVision: true }
    }
    if (benefit.BenefitType === BENEFIT_TYPE_GROUP_HEALTH) {
      return { ...prev, hasGroupHealth: true }
    }
    if (benefit.BenefitType === BENEFIT_TYPE_HEALTH) {
      return { ...prev, hasHealthBundle: true }
    }
    additionalBenefits.push(benefit)
    return prev
  }, {})

  return {
    ...hasBenefits,
    hasAdditionalBenefits: additionalBenefits.length > 0,
  }
})

export const getCompanyLogoUrlSelector = createSelector(
  getClientSettings,
  (settings) => settings.CompanyLogoUrl
)

export const getHelpPhoneNumber = createSelector(
  getClientSettings,
  (settings) => settings.CustomerServicePhoneNumber
)

/* CLIENT BENEFITS ENROLLMENT */
export const getClientBenefitsEnrollment = createSelector(
  getSessionSelector,
  (userSession) => userSession.clientBenefitsEnrollment
)

export const isHSASwap = createSelector(
  getClientBenefitsEnrollment,
  (clientBenefitsEnrollment) => clientBenefitsEnrollment.HSASwap
)

// Client benefit configurations
export const benefitIsAvailable = createSelector(
  getClientSettings,
  (clientSettings) => (benefitType) => {
    const BenefitCounts = clientSettings.BenefitCounts
    if (!BenefitCounts) {
      return false
    }

    return !!BenefitCounts.find((item) => item.BenefitType === benefitType) // return bool
  }
)

/* ICHRA */
export const getICHRAAvailablity = createSelector(
  (state) => state[NAME],
  (userSession) => userSession.ichra.available
)

/* Providers */
export const getUserLocation = createSelector(
  (state) => state[NAME],
  (userSession) => userSession.location
)

export const getUserProviders = createSelector(
  (state) => state[NAME],
  (userSession) => userSession.providers
)

export const getSelectedPersonsByProviderId = (providerId, state) =>
  createSelector(
    () => state[NAME],
    (userSession) => {
      const provider = userSession.providers.find((p) => p.id === providerId)
      if (!provider) return []
      return provider.selected
    }
  )(state)

export const isPersonSelectedByProviderId = (providerId, personPublicKey, state) =>
  createSelector(
    () => state[NAME],
    (userSession) => {
      const provider = userSession.providers.find((p) => p.id === providerId)
      if (!provider) return false
      return provider.selected.some((s) => s.id === personPublicKey)
    }
  )(state)

/* BENEFITS */
export const interestedBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_INTERESTED)

export const visionBenefitIsAvailable = (state) => benefitIsAvailable(state)(BENEFIT_TYPE_VISION)

export const dentalBenefitIsAvailable = (state) => benefitIsAvailable(state)(BENEFIT_TYPE_DENTAL)

export const employeeAssistanceBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_EMPLOYEE_ASSISTANCE)

export const healthBundleBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_HEALTH)

export const groupHealthBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_GROUP_HEALTH)

export const FSABenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_FSA) &&
  !cart.selectors.getHSABenefitProduct(state) &&
  !getEnrollmentSession(state).IntentToElectHSA

export const LPFSABenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_LPFSA) &&
  (cart.selectors.getHSABenefitProduct(state) || getEnrollmentSession(state).IntentToElectHSA)

export const DCFSABenefitIsAvailable = (state) => benefitIsAvailable(state)(BENEFIT_TYPE_DCFSA)

export const FTABenefitIsAvailable = (state) => benefitIsAvailable(state)(BENEFIT_TYPE_FTA)

export const basicLifeBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_BASIC_LIFE)

export const voluntaryLifeBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_VOLUNTARY_LIFE)

export const voluntaryLifeSimpleBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_VOLUNTARY_LIFE_SIMPLE)

export const shortTermDisabilityBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_SHORT_TERM_DISABILITY)

export const longTermDisabilityBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_LONG_TERM_DISABILITY)

export const voluntaryLongTermDisabilityBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_VOLUNTARY_LONG_TERM_DISABILITY)

export const voluntaryShortTermDisabilityBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_VOLUNTARY_SHORT_TERM_DISABILITY)

export const voluntaryADDBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_VOLUNTARY_AD_D)

export const legalPlanBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_LEGAL_PLAN)

export const legalShieldBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_LEGAL_SHIELD)

export const hospitalIndemnityBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_HOSPITAL_INDEMNITY)

export const accidentBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_ACCIDENT)

export const chiropracticBenefitIsAvailable = (state) => {
  const employeeHasMedical = cart.selectors.employeeHasMedicalProduct(state)
  return benefitIsAvailable(state)(BENEFIT_TYPE_CHIROPRACTIC) && employeeHasMedical
}

export const criticalIllnessBenefitIsAvailable = (state) =>
  benefitIsAvailable(state)(BENEFIT_TYPE_CRITICAL_ILLNESS)

export const isQLEAvailable = (state) => {
  const { Benefits } = getWaiter(state, `${GET_USER_BENEFITS}_`).response || {
    Benefits: [],
  }
  const enrollmentSession = getEnrollmentSession(state)
  const now = moment()
  const enrollmentStartDate = moment(enrollmentSession.EnrollmentStartDate)
  const enrollmentEndDate = moment(enrollmentSession.EnrollmentEndDate) // disregard enrollmentSession.EnrollmentCutoffTime here, since QLE will fail due to overlapping enrollment
  const isWithinEnrollmentPeriod =
    now.isSameOrAfter(enrollmentStartDate, 'hour') && now.isSameOrBefore(enrollmentEndDate, 'hour')

  return (
    Boolean(enrollmentSession.QualifyingLifeEvent) ||
    (Benefits &&
      Benefits.find((benefit) => {
        return (
          [BENEFIT_TYPE_DENTAL, BENEFIT_TYPE_HEALTH, BENEFIT_TYPE_VISION].includes(
            benefit.BenefitType
          ) && moment().isSameOrBefore(benefit.BenefitEndDate, 'day')
        )
      }) &&
      !isWithinEnrollmentPeriod)
  )
}

// Uses current enrollment because previous one doesn't exists in QLE Removal flow once the new enrollment created is not active yet.
export const getPreviousPaycheck = createSelector(getEnrollmentSession, (enrollment) => {
  return getCartSummary({
    Cart: {
      cart: enrollment?.Cart ?? [],
    },
  })?.total
})

export const getPlanMap = (state) => {
  const { Benefits } = getWaiter(state, `${GET_USER_BENEFITS}_`).response || {
    Benefits: [],
  }
  const healthBenefits = Benefits.filter((benefit) => benefit.BenefitType === BENEFIT_TYPE_HEALTH)
  return healthBenefits.reduce((prev, benefit) => {
    const persons = benefit.Persons.reduce((acc, person) => {
      return {
        ...acc,
        [person.Id]: {
          Carrier: benefit.CarrierName,
          ...person,
          FullName: `${person.FirstName} ${person.LastName}`,
        },
      }
    }, [])
    return { ...prev, ...persons }
  }, {})
}

export const getBenefitById = (id, state) =>
  createSelector(getPlanMap, (planMap) => planMap[id])(state)
