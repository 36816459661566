import styled from 'styled-components'
import { colors } from '@hixme-ui/theme'

import { PLAN_METAL_BRONZE, PLAN_METAL_SILVER, PLAN_METAL_GOLD } from '@hixme/health-plan-metals'

import FlatButton from '@hixme-ui/button'
import Container from '@hixme-ui/container'

export const PLAN_COLORS = {
  [PLAN_METAL_BRONZE]: '#C1AF78',
  [PLAN_METAL_SILVER]: '#B9C5CD',
  [PLAN_METAL_GOLD]: '#EEC700',
}

export const CardHeader = styled(Container)`
  align-items: center;
  background: ${({ metal }) => PLAN_COLORS[metal] || '#5874d0'};
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: column;
  height: 7px;
  justify-content: center;
  margin: 0;
  padding: 0;
`

export const CardHeaderControls = styled(Container)`
  align-items: center;
  background: #fff;
  box-shadow: 0 3px 2px 0 ${colors.grey100};
  color: #8f9da8;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`

export const ActionButton = styled(FlatButton)`
  background: ${({ metal }) => PLAN_COLORS[metal] || '#5874d0'};
  border-radius: 6px;
  color: #fff;
  display: flex;
  height: 38px;
  min-width: 78px;
  :hover:enabled {
    background: ${({ metal }) => PLAN_COLORS[metal] || '#5874d0'};
    cursor: pointer;
    filter: brightness(110%);
  }
`
