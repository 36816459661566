import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { Row } from 'react-flexbox-grid'
import Title from '@hixme-ui/title'
import Text from '@hixme-ui/text'
import Container from '@hixme-ui/container'

// project
import {
  DENTAL_PRODUCTS_PATH,
  EXPENSES_PATH,
  GROUP_HEALTH_PATH,
  OVERVIEW_PATH,
  PROVIDER_LOOKUP_PATH,
  VIEW_PATH,
  VISION_PRODUCTS_PATH,
} from 'routes/path-names'
import ClearContainer from 'components/ClearContainer'

// route
import allowedList from '../../FSAMarketing/allowedList'
import InfoCard from './InfoCard'
import AdditionalBenefitsIcon from './assets/AdditionalBenefits.png'
import DentalIcon from './assets/Dental.png'
import HealthBundleIcon from './assets/HealthBundle.png'
import PersonalInfoIcon from './assets/PersonalInfo.png'
import VisionIcon from './assets/Vision.png'
import FinishEnrollmentIcon from './assets/FinishEnrollment.png'
import HelpCard from './HelpCard'
import MyExpensesRequest from './MyExpensesRequest/MyExpensesRequest'
import MyCurrentBenefits from './MyCurrentBenefits'
import MsgAllowedCardList from './MessagesCard/MsgAllowedCardList'
import { isProduction } from '../../../modules/app-config/environment'

import { aflacClientList, AflacInfo } from '../../Aflac'
import MessagesCard from './MessagesCard'
import { QLEAnnouncementModal } from 'routes/LifeEvents/QLEAnnouncementModal/QLEAnnouncementModal'
import { QLECard } from './QLECard'
import { VersionCodeDebugBox } from '../../../components/VersionCodeDebugBox'
import { OptOutEnrollment } from 'routes/Benefits/HealthBundles/components/OptOutEnrollment/OptOutEnrollment'
import useFeatureFlags from '../../../hooks/useFeatureFlags'

export const Home = ({
  enrollmentDurationMessage,
  hasNeverSawProviderQuestion,
  benefits,
  isICHRAAvailable,
  enrollmentIsActive,
  isCartCommitted,
  person,
  dentalComplete,
  visionComplete,
  healthComplete,
  groupComplete,
  additionalBenefitsComplete,
  needsToSignHealthBundles,
  hasHealthBundleBenefit,
  isGuestUser,
  isEnrolling,
  isQLEAvailable,
  enrollmentSession,
  prevEnrollment,
  personsEnrolledInMedicare,
  getCutoffTime,
  isPendingInformation,
}) => {
  const { flags } = useFeatureFlags()
  const enrollmentCutoffTime = flags['product.enrollme.enrollment-cutoff-time']

  useEffect(() => {
    enrollmentCutoffTime && getCutoffTime(enrollmentCutoffTime)
  }, [enrollmentCutoffTime])

  let myPersonalInfoCardState = 'disabled'
  let myPersonalInfoCardUrl = VIEW_PATH

  const isQLEEnrollment = Boolean(enrollmentSession.QualifyingLifeEvent)
  const isGuestUserOrEnrolling = isGuestUser || isEnrolling

  if (isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    myPersonalInfoCardState = 'completed'
    if (!isPendingInformation) {
      myPersonalInfoCardUrl =
        hasNeverSawProviderQuestion && !enrollmentIsActive ? PROVIDER_LOOKUP_PATH : OVERVIEW_PATH
    }
  } else if (isGuestUserOrEnrolling && !person.VerifiedPersonalInfo) {
    myPersonalInfoCardState = 'start'
  }

  let dentalCardState = 'disabled'
  if (isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    dentalComplete ? (dentalCardState = 'completed') : (dentalCardState = 'start')
  }

  let visionCardState = 'disabled'
  if (isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    visionComplete ? (visionCardState = 'completed') : (visionCardState = 'start')
  }

  let healthCardState = 'disabled'
  if (!hasHealthBundleBenefit && isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    groupComplete ? (healthCardState = 'completed') : (healthCardState = 'start')
  } else if (isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    healthComplete ? (healthCardState = 'completed') : (healthCardState = 'start')
  }

  let additionalBenefitsCardState = 'disabled'
  if (isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    additionalBenefitsComplete
      ? (additionalBenefitsCardState = 'completed')
      : (additionalBenefitsCardState = 'start')
  }

  let finishEnrollmentCardState = 'disabled'
  if (isGuestUserOrEnrolling && person.VerifiedPersonalInfo) {
    finishEnrollmentCardState = 'start'
  }

  let showPostEnrollmentCards = false
  if ((isCartCommitted || enrollmentIsActive || isQLEEnrollment) && !isGuestUser) {
    showPostEnrollmentCards = true
  }

  // The FSA Card will never be in a disabled state
  const fsaMarketingCardState = 'completed'

  let showMessageCard = false
  if (isProduction()) {
    if (MsgAllowedCardList.includes(person.ClientPublicKey)) {
      showMessageCard = true
    }
  } else {
    showMessageCard = true
  }

  const prevHealthBundles =
    prevEnrollment?.Cart?.filter((bundle) => bundle.BenefitType === 'HealthBundle') ?? []

  // Quick fix for EMS-2659
  const isValidPrevEnrollmentStatus = (prevEnrollment?.CartStatus ?? '') === 'Committed'

  const isPrevEnrollmentDeclined = prevEnrollment
    ? prevHealthBundles.every((bundle) => (bundle.Benefits ?? []).length === 0)
    : false // not having a prev enrollment != declined benefit last time, so set to false

  const enrollmentFamilyMembers =
    enrollmentSession?.FamilyGroups?.FamilyGroups.map((group) => group.FamilyMembers).flat() ?? []

  const getMyPersonalInfoRoute = useCallback(() => {
    if (hasNeverSawProviderQuestion && !enrollmentIsActive) return PROVIDER_LOOKUP_PATH
    if (isPendingInformation && isEnrolling) return VIEW_PATH
    return OVERVIEW_PATH
  }, [
    OVERVIEW_PATH,
    PROVIDER_LOOKUP_PATH,
    VIEW_PATH,
    hasNeverSawProviderQuestion,
    enrollmentIsActive,
    isPendingInformation,
    isEnrolling,
  ])

  return (
    <ClearContainer>
      {showPostEnrollmentCards && aflacClientList.includes(person.ClientPublicKey) && <AflacInfo />}
      <Container flex noPadding justifyContent="center">
        {!showPostEnrollmentCards && (
          <Container flex noPadding width="970px" direction="column">
            {isPrevEnrollmentDeclined && isValidPrevEnrollmentStatus && enrollmentSession.Id && (
              <Row>
                <OptOutEnrollment
                  enrollmentPublicKey={enrollmentSession.Id}
                  members={enrollmentFamilyMembers}
                  membersEnrolledInMedicare={personsEnrolledInMedicare}
                />
              </Row>
            )}
            <Row between="xs" bottom="xs" style={{ margin: '20px 0' }}>
              <Title smaller>My Benefits</Title>
              <div>
                <Text red bold style={{ marginRight: '4px' }}>
                  {enrollmentDurationMessage.days}
                </Text>
                <Text>{enrollmentDurationMessage.message}</Text>
              </div>
            </Row>
            <Row>
              <InfoCard
                Image={PersonalInfoIcon}
                title="My Personal Info"
                message="View your personal information as well as dependent information."
                url={myPersonalInfoCardUrl}
                state={myPersonalInfoCardState}
              />
              {hasHealthBundleBenefit && (
                <InfoCard
                  Image={HealthBundleIcon}
                  title="My Health Benefits"
                  message="Search for health insurance."
                  url={myPersonalInfoCardUrl}
                  state={healthCardState}
                />
              )}
              {benefits.hasGroupHealth && (
                <InfoCard
                  Image={HealthBundleIcon}
                  title="My Health"
                  message="Elect a medical plan for you or members of your family who need it."
                  url={GROUP_HEALTH_PATH}
                  state={healthCardState}
                />
              )}
              {benefits.hasDental && (
                <InfoCard
                  Image={DentalIcon}
                  title="My Dental"
                  message="See your dental plan options."
                  url={DENTAL_PRODUCTS_PATH}
                  state={dentalCardState}
                />
              )}
              {benefits.hasVision && (
                <InfoCard
                  Image={VisionIcon}
                  title="My Vision"
                  message="See your vision plan options."
                  url={VISION_PRODUCTS_PATH}
                  state={visionCardState}
                />
              )}
              {benefits.hasAdditionalBenefits && (
                <InfoCard
                  Image={AdditionalBenefitsIcon}
                  title="My Additional Benefits"
                  message="See your additional benefits made available by your employer."
                  url="/benefits/fsa"
                  state={additionalBenefitsCardState}
                />
              )}
              <InfoCard
                Image={FinishEnrollmentIcon}
                title="Finish Enrollment"
                message="Confirm your benefits, sign the forms and finish enrolling."
                url="/cart"
                buttonLabel="Finish & Confirm Benefits"
                state={finishEnrollmentCardState}
              />
              {allowedList.includes(person.ClientPublicKey) && (
                <InfoCard
                  Image={AdditionalBenefitsIcon}
                  title="Flexible Spending Account"
                  message="Learn how to set aside pre-tax dollars for eligible expenses"
                  url="/fsa-marketing"
                  buttonLabel="View"
                  state={fsaMarketingCardState}
                  bannerText="DON'T FORGET"
                />
              )}
            </Row>

            {isEnrolling && <MyCurrentBenefits ichra={isICHRAAvailable} />}

            <Row style={{ margin: '40px 0 20px 0' }}>
              <Title smaller>My Resources</Title>
            </Row>

            <Row>
              <HelpCard />
            </Row>
          </Container>
        )}
        {showPostEnrollmentCards && (
          <Container flex noPadding width="970px" direction="column">
            <Row>
              {needsToSignHealthBundles && (
                <InfoCard
                  Image={FinishEnrollmentIcon}
                  title="Signature is required"
                  message="Sign forms and finish enrolling."
                  url={isCartCommitted ? '/checkout/application' : '/checkout/pcp-disclosure'}
                  state="start"
                />
              )}

              <InfoCard
                Image={AdditionalBenefitsIcon}
                title="My Benefits Summary"
                message="View a summary of your benefits."
                url="/benefits/summary"
                state="completed"
              />
              {isICHRAAvailable && (
                <MyExpensesRequest
                  title="My Expense Requests"
                  message="Take advantage of your Expense Reimbursement Benefit"
                  url={EXPENSES_PATH}
                  state="completed"
                />
              )}
              <InfoCard
                Image={PersonalInfoIcon}
                title="My Personal Info"
                message="View your personal information as well as dependent information."
                url={getMyPersonalInfoRoute()}
                state="completed"
              />
              {showMessageCard && <MessagesCard />}
              {allowedList.includes(person.ClientPublicKey) && (
                <InfoCard
                  Image={AdditionalBenefitsIcon}
                  title="Flexible Spending Account"
                  message="Learn how to set aside pre-tax dollars for eligible expenses"
                  url="/fsa-marketing"
                  buttonLabel="View"
                  state={fsaMarketingCardState}
                  bannerText="DON'T FORGET"
                />
              )}
              <HelpCard />
              {isQLEAvailable && <QLECard blockQleActions={isEnrolling} />}
            </Row>
            {isQLEAvailable && <QLEAnnouncementModal id={person.Id} />}
          </Container>
        )}
      </Container>
      <VersionCodeDebugBox />
    </ClearContainer>
  )
}

Home.displayName = 'Home'
Home.propTypes = {
  enrollmentDurationMessage: PropTypes.object,
  hasNeverSawProviderQuestion: PropTypes.bool,
  benefits: PropTypes.object,
  enrollmentIsActive: PropTypes.bool,
  isCartCommitted: PropTypes.bool,
  dentalComplete: PropTypes.bool,
  visionComplete: PropTypes.bool,
  healthComplete: PropTypes.bool,
  groupComplete: PropTypes.bool,
  additionalBenefitsComplete: PropTypes.bool,
  person: PropTypes.object,
  needsToSignHealthBundles: PropTypes.bool,
  isGuestUser: PropTypes.bool,
  hasHealthBundleBenefit: PropTypes.bool,
  isICHRAAvailable: PropTypes.bool,
  isEnrolling: PropTypes.bool,
}

Home.defaultProps = {
  hasNeverSawProviderQuestion: false,
  dentalComplete: false,
  visionComplete: false,
  healthComplete: false,
  groupComplete: false,

  enrollmentDurationMessage: {},
  benefits: {},
  enrollmentComplete: false,
  isCartCommitted: false,

  additionalBenefitsComplete: false,
  person: {},
  needsToSignHealthBundles: false,
  hasHealthBundleBenefit: true,
  isGuestUser: false,
  isICHRAAvailable: false,
  isEnrolling: false,
}

export default Home
