import React from 'react'
import { Button, Dropdown, MenuProps, ButtonProps, Space } from '@surecompanies/core_components'
import { LinkOutlined, DownOutlined } from '@ant-design/icons'

export type BenefitsSummaryButtonType = {
  items: MenuProps['items']
  label: string
  onItemSelected: (key: React.Key) => {}
  as?: ButtonProps['type']
}

export default function BenefitsSummaryButton({
  items,
  onItemSelected,
  label,
  as = 'default',
}: BenefitsSummaryButtonType) {
  return items.length === 1 ? (
    <>
      <Button
        type={as}
        onClick={() => {
          onItemSelected(items[0]?.key)
        }}
      >
        <Space>
          <LinkOutlined />
          {label}
        </Space>
      </Button>
    </>
  ) : (
    <Dropdown menu={{ items, onClick: ({ key }) => onItemSelected(key) }}>
      <Button>
        <>
          {label}
          <DownOutlined />
        </>
      </Button>
    </Dropdown>
  )
}
