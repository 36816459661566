/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Separator from '@hixme-ui/separator'
import { Alert } from '@surecompanies/core_components'
import { Stack, Typography } from '@sureco/design-system'

// project
import { PersonBenefitPrice } from 'store/modules/persons/components'
import ICHRATooltip from 'routes/Benefits/HealthBundles/components/BundlePlanTypeTooltips/components/ICHRATooltip/ICHRATooltip'

export const CartSummary = ({
  cartSummary,
  isPreviousPaycheckVisibible,
  previousPaycheckTotal,
}) => (
  <div style={{ fontFamily: 'Avenir Next' }}>
    <Text div jumbo fontWeight="600" style={{ marginBottom: '25px', color: '#3a4951' }}>
      My Benefits Summary
    </Text>
    {cartSummary.items.map((item, idx) => (
      <Container
        flex
        noPadding
        justifyContent="space-between"
        // eslint-disable-next-line react/no-array-index-key
        key={`Benefit-${item.Name}-${idx}`}
      >
        <Text bold={item.inCart} light={!item.inCart}>
          {item.Allowance ? (
            <span data-tip data-for={item.name}>
              <Text fontWeight={item.inCart ? '600' : '400'}>
                {item.Name}{' '}
                <Text primary fontWeight="600">
                  {item.Allowance}
                </Text>
              </Text>
              <ICHRATooltip tooltipId={item.name} />
            </span>
          ) : (
            <React.Fragment>
              <Text fontWeight={item.inCart ? '600' : '400'}>
                {item.Medicare ? <Text fontWeight="600">Medicare</Text> : item.Name}
              </Text>
            </React.Fragment>
          )}
        </Text>
        {item.Allowance ? (
          <Text primary fontWeight="600">
            Included
          </Text>
        ) : !item.Medicare && !item.isPriceDefined ? (
          <PersonBenefitPrice
            price={item.DisplayValue || item.Price}
            compact
            bold
            primary={item.Medicare ? false : item.Primary}
            blue={item.Medicare}
          />
        ) : (
          <Text>TBD</Text>
        )}
      </Container>
    ))}
    <Separator light size="1px" />
    {isPreviousPaycheckVisibible ? (
      <Stack space={12}>
        <Container flex noPadding width="100%" justifyContent="space-between" alignItems="center">
          <Text light>New per paycheck {cartSummary.total < 0 ? 'credit' : 'deduction'}:</Text>
          <PersonBenefitPrice compact large bold price={cartSummary.total} />
        </Container>
        <Alert
          type="info"
          closable={false}
          banner
          showIcon={false}
          style={{ borderRadius: 12 }}
          message={
            <Stack
              direction="row"
              space={16}
              style={{ flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography size={14} color="#0A3673">
                Previous per paycheck {previousPaycheckTotal < 0 ? 'credit' : 'deduction'}
              </Typography>
              <PersonBenefitPrice compact bold price={previousPaycheckTotal} />
            </Stack>
          }
        />
        <Alert
          banner
          showIcon={false}
          type="warning"
          style={{ color: '#714111', borderRadius: 12 }}
          message="Actual changes to your paycheck deduction are conditional upon SureCo's verification with your insurance carrier."
        />
      </Stack>
    ) : (
      <Container flex noPadding width="100%" justifyContent="space-between" alignItems="center">
        <Text light>Per paycheck {cartSummary.total < 0 ? 'credit' : 'deduction'}:</Text>
        <PersonBenefitPrice compact large bold price={cartSummary.total} />
      </Container>
    )}
  </div>
)

CartSummary.displayName = 'CartSummary'

CartSummary.propTypes = {
  cartSummary: PropTypes.object.isRequired,
}

export default CartSummary
