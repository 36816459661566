import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { Row } from 'react-flexbox-grid'
import Title from '@hixme-ui/title'

// route
import { EXPENSES_PATH } from 'routes/path-names'
import InfoCard from '../InfoCard'
import AdditionalBenefitsIcon from '../assets/AdditionalBenefits.png'
import MyExpensesRequest from '../MyExpensesRequest/MyExpensesRequest'

const MyCurrentBenefits = ({ ichra }) => (
  <div>
    <Row style={{ margin: '40px 0 20px 0' }}>
      <Title smaller>My Current Benefits</Title>
    </Row>

    <Row>
      <InfoCard
        Image={AdditionalBenefitsIcon}
        title="My Benefits Summary"
        message="View a summary of your benefits."
        url="/benefits/summary"
        state="completed"
      />
      {ichra && (
        <MyExpensesRequest
          title="My Expense Requests"
          message="Take advantage of your Expense Reimbursement Benefit"
          url={EXPENSES_PATH}
          state="completed"
        />
      )}
    </Row>
  </div>
)

MyCurrentBenefits.propTypes = {
  ichra: PropTypes.bool,
}

MyCurrentBenefits.defaultProps = {
  ichra: false,
}

export default MyCurrentBenefits
