import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getHealthGroups } from 'store/modules/user-session/selectors'

import Group from '../../../../Profile/routes/Groups/model/Group'
import { setCurrentGroupId, setGroupIndex } from '../../modules/health-bundles/actions'
import { getCurrentGroupId, getCurrentGroupIndex } from '../../modules/health-bundles/selectors'
import GroupOption from './components/GroupOption'
import GroupValue from './components/GroupValue'

import CustomMultiSelect from './style'

const GroupDropdownPicker = ({ list, value, onGroupChange, isVisible }) =>
  isVisible ? (
    <CustomMultiSelect
      searchable={false}
      clearable={false}
      placeholder="Select a Group"
      options={list}
      optionComponent={GroupOption}
      value={value}
      valueComponent={GroupValue}
      onChange={(val) => onGroupChange(val, list)}
      style={{ maxWidth: '450px' }}
    />
  ) : null

const mapStateToProps = (state) => {
  const currentGroupId = getCurrentGroupId(state)
  const currentGroupIndex = getCurrentGroupIndex(state) || 0
  const groups = getHealthGroups(state) || []
  if (groups.length === 0) {
    return {
      isVisible: false,
    }
  }
  const list = groups.map((group) => ({
    id: group.Id,
    name: group.GroupName,
    isSelected: group.SelectedPlanName,
    area: `${group.StateProvince}${group.RatingArea}`,
    members: group.FamilyMembers.map((member) => member.FirstName).join(' '),
    model: new Group(group),
  })) || [{ id: '', value: '' }]
  const value = currentGroupId
    ? list.find((group) => group.id === currentGroupId)
    : list[currentGroupIndex] || {}

  return {
    value,
    list: list.map((group) => ({
      ...group,
      currentSelected: group.id === currentGroupId,
    })),
    isVisible: true,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onGroupChange: (value, list) => {
    const index = list.findIndex((item) => item.id === value.id) || 0
    dispatch(setCurrentGroupId(list[index].id))
    dispatch(setGroupIndex(index))
  },
})

GroupDropdownPicker.propTypes = {
  onGroupChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDropdownPicker)
