import React, { useContext } from 'react'
import { LifeEventsContext } from 'routes/LifeEvents/LifeEventsContext'
import { Button } from '@surecompanies/core_components'
import { Typography } from '@sureco/design-system'

export type AddBenefitButtonProps = {
  bundle: { BundlePublicKey: string; added: boolean }
  isProcessing?: boolean
  onRemoveFromCart: (bundlePublicKey: string, qleEnrollmentKey?: string) => void
  onAddToCart: (bundlePublicKey: string, qleEnrollmentKey?: string) => void
}

const AddBenefitButton = ({
  bundle: { BundlePublicKey, added },
  isProcessing,
  onRemoveFromCart,
  onAddToCart,
}: AddBenefitButtonProps) => {
  const qleCtx = useContext(LifeEventsContext)

  const onAddToCartHandler = () => {
    if (qleCtx) {
      const qleEnrollmentKey = qleCtx.state.shared.record?.qleResult[0]?.enrollmentPublicKey
      if (!qleEnrollmentKey) {
        throw new Error('QLE add to cart without QLE enrollment key')
      }
      onAddToCart(BundlePublicKey, qleEnrollmentKey)
    } else {
      onAddToCart(BundlePublicKey)
    }
  }

  const onRemoveFromCartHandler = () => {
    if (qleCtx) {
      const qleEnrollmentKey = qleCtx.state.shared.record?.qleResult[0]?.enrollmentPublicKey
      if (!qleEnrollmentKey) {
        throw new Error('QLE add to cart without QLE enrollment key')
      }
      onRemoveFromCart(BundlePublicKey, qleEnrollmentKey)
    } else {
      onRemoveFromCart(BundlePublicKey)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {added ? (
        <Button
          size="large"
          loading={isProcessing}
          onClick={onRemoveFromCartHandler}
          style={{ backgroundColor: '#3A4951', color: 'white' }}
          block
        >
          <Typography size={14} bold>
            {isProcessing ? 'Removing from Benefits...' : 'Added to My Benefits'}
          </Typography>
        </Button>
      ) : (
        <Button
          type="primary"
          loading={isProcessing}
          size="large"
          onClick={onAddToCartHandler}
          block
        >
          <Typography size={14} bold>
            {isProcessing ? 'Adding to Benefits...' : 'Add to My Benefits'}
          </Typography>
        </Button>
      )}
    </div>
  )
}

export default AddBenefitButton
