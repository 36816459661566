import {
  BENEFIT_TYPE_ACCIDENT,
  BENEFIT_TYPE_BASIC_LIFE,
  BENEFIT_TYPE_CRITICAL_ILLNESS,
  BENEFIT_TYPE_CHIROPRACTIC,
  BENEFIT_TYPE_DCFSA,
  BENEFIT_TYPE_DENTAL,
  BENEFIT_TYPE_EMPLOYEE_ASSISTANCE,
  BENEFIT_TYPE_FSA,
  BENEFIT_TYPE_FTA,
  BENEFIT_TYPE_GROUP_HEALTH,
  BENEFIT_TYPE_HEALTH,
  BENEFIT_TYPE_HOSPITAL_INDEMNITY,
  BENEFIT_TYPE_HSA,
  BENEFIT_TYPE_INTERESTED,
  BENEFIT_TYPE_LEGAL_PLAN,
  BENEFIT_TYPE_LEGAL_SHIELD,
  BENEFIT_TYPE_LONG_TERM_DISABILITY,
  BENEFIT_TYPE_LPFSA,
  BENEFIT_TYPE_SHORT_TERM_DISABILITY,
  BENEFIT_TYPE_VISION,
  BENEFIT_TYPE_VOLUNTARY_AD_D,
  BENEFIT_TYPE_VOLUNTARY_LIFE_SIMPLE,
  BENEFIT_TYPE_VOLUNTARY_LIFE,
  BENEFIT_TYPE_VOLUNTARY_LONG_TERM_DISABILITY,
  BENEFIT_TYPE_ICHRA,
} from '@hixme/benefit-types'

import {
  ACCIDENT_PRODUCTS_PATH,
  BASIC_LIFE_PRODUCTS_PATH,
  CRITICAL_ILLNESS_PRODUCTS_PATH,
  CHIROPRACTIC_PRODUCTS_PATH,
  DCFSA_PRODUCTS_PATH,
  DENTAL_PRODUCTS_PATH,
  EMPLOYEE_ASSISTANCE_PRODUCTS_PATH,
  FSA_PRODUCTS_PATH,
  FTA_PRODUCTS_PATH,
  GROUP_HEALTH_PATH,
  HEALTH_PRODUCTS_PATH,
  HOSPITAL_INDEMNITY_PRODUCTS_PATH,
  LEGAL_PLAN_PRODUCTS_PATH,
  LEGAL_SHIELD_PRODUCTS_PATH,
  LONG_TERM_DISABILITY_PRODUCTS_PATH,
  LPFSA_PRODUCTS_PATH,
  SHORT_TERM_DISABILITY_PRODUCTS_PATH,
  VISION_PRODUCTS_PATH,
  VOLUNTARY_AD_D_PRODUCTS_PATH,
  VOLUNTARY_LIFE_PRODUCTS_PATH,
  VOLUNTARY_LIFE_SIMPLE_PRODUCTS_PATH,
  VOLUNTARY_LONG_TERM_DISABILITY_PRODUCTS_PATH,
} from 'routes/path-names'

import HealthIcon from './assets/HealthSmall.png'
import VisionIcon from './assets/VisionSmall.png'
import DentalIcon from './assets/DentalSmall.png'
import OtherProductsIcon from './assets/SupplementalSmall.png'
import SmokerIcon from './assets/SmokerImg.png'

const showPersonsForProducts = [
  BENEFIT_TYPE_DENTAL,
  BENEFIT_TYPE_GROUP_HEALTH,
  BENEFIT_TYPE_HEALTH,
  BENEFIT_TYPE_VISION,
]

const hideWhenNotShopped = [BENEFIT_TYPE_INTERESTED, BENEFIT_TYPE_HSA]

const hideWhenShopped = [BENEFIT_TYPE_INTERESTED]

export const isICHRA = (productType) => BENEFIT_TYPE_ICHRA === productType
export const isHealthBenefit = (productType) =>
  [BENEFIT_TYPE_HEALTH, BENEFIT_TYPE_GROUP_HEALTH].includes(productType)
export const isMedicare = (planType) => planType === 'MC' || planType === 'MEDICARE'

export const shouldRenderNotShoppedProduct = (productType) =>
  !hideWhenNotShopped.includes(productType)

export const shouldRenderShoppedProduct = (productType) => !hideWhenShopped.includes(productType)

export const shouldShowPersonsForProductType = (productType) =>
  showPersonsForProducts.includes(productType)

export const shouldShowSmokerIcon = (Persons, BenefitType) =>
  isHealthBenefit(BenefitType) && Persons && Persons.some((person) => person.Smoker)

export const getSmokerIcon = () => SmokerIcon

export const getIconForProductType = (productType) => {
  if (productType === BENEFIT_TYPE_HEALTH || productType === BENEFIT_TYPE_GROUP_HEALTH) {
    return HealthIcon
  }

  if (productType === BENEFIT_TYPE_DENTAL) {
    return DentalIcon
  }

  if (productType === BENEFIT_TYPE_VISION) {
    return VisionIcon
  }

  return OtherProductsIcon
}

export const getProductUrl = (productType) => {
  if (productType === BENEFIT_TYPE_HEALTH) {
    return HEALTH_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_DENTAL) {
    return DENTAL_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_EMPLOYEE_ASSISTANCE) {
    return EMPLOYEE_ASSISTANCE_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_VISION) {
    return VISION_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_FSA) {
    return FSA_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_LPFSA) {
    return LPFSA_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_DCFSA) {
    return DCFSA_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_FTA) {
    return FTA_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_HOSPITAL_INDEMNITY) {
    return HOSPITAL_INDEMNITY_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_VOLUNTARY_LIFE) {
    return VOLUNTARY_LIFE_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_VOLUNTARY_LIFE_SIMPLE) {
    return VOLUNTARY_LIFE_SIMPLE_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_VOLUNTARY_AD_D) {
    return VOLUNTARY_AD_D_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_VOLUNTARY_LONG_TERM_DISABILITY) {
    return VOLUNTARY_LONG_TERM_DISABILITY_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_SHORT_TERM_DISABILITY) {
    return SHORT_TERM_DISABILITY_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_LEGAL_SHIELD) {
    return LEGAL_SHIELD_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_LEGAL_PLAN) {
    return LEGAL_PLAN_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_CHIROPRACTIC) {
    return CHIROPRACTIC_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_BASIC_LIFE) {
    return BASIC_LIFE_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_LONG_TERM_DISABILITY) {
    return LONG_TERM_DISABILITY_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_GROUP_HEALTH) {
    return GROUP_HEALTH_PATH
  }

  if (productType === BENEFIT_TYPE_CRITICAL_ILLNESS) {
    return CRITICAL_ILLNESS_PRODUCTS_PATH
  }

  if (productType === BENEFIT_TYPE_ACCIDENT) {
    return ACCIDENT_PRODUCTS_PATH
  }

  return ''
}

export const getProductUrlLabel = (productType) => {
  const shopLabels = [BENEFIT_TYPE_HEALTH, BENEFIT_TYPE_DENTAL, BENEFIT_TYPE_VISION]
  if (shopLabels.includes(productType)) return 'Shop'
  return 'Info'
}

export const getShopUrlForProductType = (productType) => ({
  url: getProductUrl(productType),
  label: getProductUrlLabel(productType),
})
