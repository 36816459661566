import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'

import CartSummaryModal from 'components/Cart/CartSummaryModal'
import classes from './Header.scss'

export const Header = ({ showHelpModal, companyName, hideCartSummary, isGuestUser }) => (
  <Container flex noPadding width="100%" height="100%">
    <Container flex noPadding width="100%" justifyContent="space-between">
      <Container flex noPadding width="100%" height="100%" alignItems="center">
        <div className={classes.separator} />
        <Link to="/">
          <Icon home light size={1.6} />
        </Link>
        <Link to="/">
          <Container flex noPadding alignItems="center">
            <img alt="logo" className={classes.logo} src="/assets/sureco_ep_logo.png" />
          </Container>
        </Link>
        <div className={classes.separator} />
        <Text larger thin upperCase>
          {companyName}
        </Text>
      </Container>

      <Container flex noPadding width="100%" alignItems="center" justifyContent="flex-end">
        <Container flex noPadding alignItems="center">
          <Text onClick={showHelpModal} cursor="pointer" style={{ marginRight: '10px' }}>
            Need help
          </Text>
          <Text onClick={showHelpModal} cursor="pointer">
            <div className={classes.circleButton}>
              <Text blue large>
                ?
              </Text>
            </div>
          </Text>
        </Container>

        <div className={classes.separator} />

        {(!hideCartSummary || isGuestUser) && <CartSummaryModal />}
      </Container>
    </Container>
  </Container>
)

Header.propTypes = {
  showHelpModal: PropTypes.func,
  companyName: PropTypes.string,
  hideCartSummary: PropTypes.bool,
  isGuestUser: PropTypes.bool,
}

Header.defaultProps = {
  showHelpModal: () => {},
  companyName: '',
  hideCartSummary: false,
  isGuestUser: false,
}

export default Header
