import styled from 'styled-components'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

export const ProviderContainer = styled(Container)`
  background: white;
  box-shadow: 0 3px 3px #cbd6db;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: 'SF Pro Text', 'Arial', 'sans-serif';
  height: auto;
  min-height: 300px;
  padding: 20px;
  position: absolute;
  left: 120px;
  bottom: 0;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  min-width: 250px;
  z-index: 1;
`

export const Title = styled(Text)`
  color: #677786;
  font-size: 16px;
  font-weight: 500;
`

export const MembersContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
`

export const ButtonContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;
  padding: 0;
`

export const NoProvidersMessage = styled(Text)`
  color: #677786;
  text-align: left;
`
