import { capitalize } from 'lodash'
import GroupPerson from './Person'

class Group {
  constructor({
    Id,
    GroupName,
    StateProvince,
    RatingArea,
    GroupType,
    SelectedPlanName,
    FamilyMembers,
  } = {}) {
    this.id = Id
    this.name = GroupName
    this.state = StateProvince
    this.ratingArea = RatingArea.toString().padStart(2, '0')
    this.ratingAreaString = `${StateProvince}${this.ratingArea}`
    this.isMedicare = GroupType === 'MEDICARE'
    this.isPlanSelected = SelectedPlanName !== null
    this.members = FamilyMembers.map(
      (person) => new GroupPerson({ ...person, isPlanSelected: this.isPlanSelected })
    )
  }

  getTitle() {
    return `Family member(s) in ${this.state} (${this.ratingAreaString})`
  }

  getSubtitle() {
    return capitalize(this.name)
  }

  getButtonText() {
    if (this.isMedicare && this.isPlanSelected) {
      return 'Medicare (auto-selected)'
    }
    if (this.isPlanSelected) return 'Plan selected'
    return `Select a plan for ${capitalize(this.name)}`
  }

  getMemberForOption() {
    return this.members.map((member) => member.firstName).join(', ')
  }

  isEmpty() {
    return this.members.length === 0
  }

  clone() {
    return new Group({
      ...this,
    })
  }
}

export default Group
