import { useMutation } from '@apollo/client'
import { GENERATE_MEDICARE_PRESIGNED_URL } from 'apollo/queries'
import axios from 'axios'

export const useMedicareDocumentsUpload = (personPublicKey: string) => {
  const [generateFileUrl] = useMutation(GENERATE_MEDICARE_PRESIGNED_URL, {
    fetchPolicy: 'no-cache', // crucial! This can't be cached or you will override previous uploads
    variables: {
      input: {
        personPublicKey,
        extension: 'pdf', // as a default
      },
    },
  })

  const uploadFile = async (url: string, file) => {
    await axios.put(url, file, {
      transformRequest: [
        (data, headers) => {
          delete headers.Authorization
          return data
        },
      ],
    })
  }

  return {
    generateFileUrl,
    uploadFile,
  }
}
