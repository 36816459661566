import { connect } from 'react-redux'
import { setModal } from '@hixme/modal'

import { selectors as userSessionSelectors } from 'store/modules/user-session'
import * as personSelectors from 'store/modules/persons/selectors'
import { HELP } from 'modal-constants'

import Header from 'components/Header'

const mapStateToProps = (state) => {
  const person = personSelectors.getPersonSelector(state)
  const companyName = userSessionSelectors.getCompanyName(state)
  const logoUrl = userSessionSelectors.getCompanyLogoUrlSelector(state)
  const enrollmentResponses = userSessionSelectors.getEnrollmentResponses(state)
  const enrollmentIsActive = userSessionSelectors.getEnrollmentIsActiveSelector(state)
  const isCartCommitted = userSessionSelectors.isCartCommitted(state)
  const isEnrollingExcludeQLE = userSessionSelectors.isEnrollingExcludeQLE(state)
  const isGuestUser = userSessionSelectors.isGuestUser(state)
  const isCreatingOrUpdating = userSessionSelectors.isCreatingOrUpdating(state)

  const hideCartSummary =
    !person.VerifiedPersonalInfo || !isEnrollingExcludeQLE || enrollmentIsActive || isCartCommitted
  return {
    logoUrl,
    enrollmentIsActive,
    enrollmentResponses,
    person,
    companyName,
    hideCartSummary,
    isGuestUser,
    isCreatingOrUpdating,
  }
}

export default connect(mapStateToProps, (dispatch) => ({
  showHelpModal: () => {
    dispatch(setModal(HELP))
  },
}))(Header)
