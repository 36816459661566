import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'

const DeclineMedicareModal = ({ onDecline, isProcessing }) => {
  const declineClickHandler = (e) => {
    e.preventDefault()
    onDecline()
  }

  return (
    <MrModal
      small
      hideCloseButton
      closeOnEsc={false}
      shouldCloseOnOverlayClick={false}
      errorDialog
      confirmHandler={declineClickHandler}
      cancelLabel="Nevermind"
      confirmLabel="Yes, Waive health"
      confirmProcessing={isProcessing}
      confirmSubmittingText="Declining..."
    >
      <Container flex flexColumn style={{ fontFamily: 'Avenir Next' }}>
        <Container flex flexColumn textCenter noPadding alignCenter>
          <Icon alert red size={3} />
          <Text larger>Are you sure you want to waive health coverage?</Text>
        </Container>
        <Container textCenter>
          <Separator light size="1px" />
          <Text medium light>
            By waiving Health coverage none of your family members will be eligible for health
            coverage through your employer.
          </Text>
        </Container>
      </Container>
    </MrModal>
  )
}

DeclineMedicareModal.displayName = 'DeclineMedicareModal'
DeclineMedicareModal.propTypes = {
  onDecline: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
}

export default DeclineMedicareModal
