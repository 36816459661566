import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import { Accordion, AccordionItem } from 'react-sanfona'

import classes from './Accordion.scss'
import GeneralBundleAccordionBody from './components/GeneralBundleAccordionBody'

const BundleAccordion = ({ Bundle, Sections, HealthPlanId }) => (
  <Accordion data-id={uuid()} activeItems={1} allowMultiple>
    {Sections.map((section, idx) => (
      <AccordionItem
        slug={idx + 1}
        key={`accordion-${section.label}`}
        expandedClassName={classes.expanded}
        titleClassName={classes.itemTitle}
        title={section.title}
      >
        <GeneralBundleAccordionBody
          bundle={Bundle}
          healthPlanId={HealthPlanId}
          list={section.list}
          label={section.label}
          showPlanId={section.showPlanId}
          showProofOfResidenceRequireMessage={section.showProofOfResidenceRequireMessage}
          notes={section.notes}
          isDeductableWaived={
            !!(
              section.notes &&
              Object.values(section.notes).indexOf('Deductible is waived') > -1
            )
          }
        />
      </AccordionItem>
    ))}
  </Accordion>
)

BundleAccordion.propTypes = {
  Bundle: PropTypes.object.isRequired,
  Sections: PropTypes.array,
  HealthPlanId: PropTypes.string,
}

BundleAccordion.defaultProps = {
  Sections: [],
  HealthPlanId: '',
  PlanType: '',
}

BundleAccordion.displayName = 'BundleAccordion'

export default BundleAccordion
