import dayjs from 'dayjs'
import UTCPlugin from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone';
import { datadogRum } from '@datadog/browser-rum';
import { BENEFIT_TYPE_HEALTH, BENEFIT_TYPE_DENTAL, BENEFIT_TYPE_VISION, BENEFIT_TYPE_ACCIDENT, BENEFIT_TYPE_BASIC_LIFE, BENEFIT_TYPE_HOSPITAL_INDEMNITY, BENEFIT_TYPE_LONG_TERM_DISABILITY } from '@hixme/benefit-types'

import { storeInstance } from 'store/createStore';
import { DateTimeFormats } from './datetime';
import { getCurrentGroupFavoriteBundles } from 'routes/Benefits/HealthBundles/modules/health-bundles/selectors'

dayjs.extend(UTCPlugin);
dayjs.extend(timezone)

export const CustomActionNames = {
    // S4H
    AddHealth: 'ADD_HEALTH',
    RemoveHealth: 'REMOVE_HEALTH',
    OpenBenefitsSummaryPDF: 'OPEN_BENEFITS_SUMMARY_PDF',
    ConfirmElection: 'CONFIRM_ELECTION',
    ConfirmQLEElection: 'CONFIRM_QLE_ELECTION',
    MarkS4HDURATION: 'MARK_S4H_DURATION',
    DeclineMedicare: 'DECLINE_MEDICARE',
    DeclineHealth: 'DECLINE_HEALTH',
    UndoDeclineHealth: 'UNDO_DECLINE_HEALTH',
    UndoDeclineMedicare: 'UNDO_DECLINE_MEDICARE',

    DeclineDental: 'DECLINE_DENTAL',
    DeclineVision: 'DECLINE_VISION',
    DeclineAccidental: 'DECLINE_ACCIDENTAL',
    DeclineBasicLife: 'DECLINE_BASIC_LIFE',
    DeclineHospitalIndemnity: 'DECLINE_HOSPITAL_INDEMNITY',
    DeclineLongTermDisability: 'DECLINE_LONG_TERM_DISABILITY',

    AddDental: 'ADD_DENTAL',
    AddVision: 'ADD_VISION',
    AddAccidental: 'ADD_ACCIDENTAL',
    AddBasicLife: 'ADD_BASIC_LIFE',
    AddHospitalIndemnity: 'ADD_HOSPITAL_INDEMNITY',
    AddLongTermDisability: 'ADD_LONG_TERM_DISABILITY',
    RemoveDental: 'REMOVE_DENTAL',
    RemoveVision: 'REMOVE_VISION',
    RemoveAccidental: 'REMOVE_ACCIDENTAL',
    RemoveBasicLife: 'REMOVE_BASIC_LIFE',
    RemoveHospitalIndemnity: 'REMOVE_HOSPITAL_INDEMNITY',
    RemoveLongTermDisability: 'REMOVE_LONG_TERM_DISABILITY',

    AddHealthToCompare: 'ADD_HEALTH_TO_COMPARE',
    RemoveHealthToCompare: 'REMOVE_HEALTH_TO_COMPARE',
    ViewHealthComparison: 'VIEW_HEALTH_COMPARISON',
    RemoveAllComparison: 'REMOVE_ALL_COMPARISON',
} as const;

/**
 * This converts an array of objects into the following format
 * ```
 * {
 *  0: arr[0],
 *  1: arr[1],
 *  ...so on
 * }
 * ```
 * This is done because objects display much better in datadog dashboard than arrays
 * @param data : any[]
 */
const convertArrayIntoObjects = (data: any[]): Record<string, any> => {
    const res: Record<string, any> = {}

    data.forEach((entry, idx) => {
        res[idx] = entry
    })

    return res;
}

const extractPersonAttributes = (person: Record<string, any>) => {
    // sometimes it comes back as PascalCase, sometimes it's camelCase ...
    const keys = Object.keys(person);
    const isPascalCase = keys.length > 0 ? /^[A-Z]$/.test(keys[0][0]) : false

    console.log(person)

    if (isPascalCase) {
        return {
            id: person.Id,
            name: person.FullName ?? (person.FirstName && person.LastName ? `${person.FirstName} ${person.LastName}` : undefined),
            email: person.EmailAddress,
            employeePublicKey: person.EmployeePublicKey,
            relationship: person.Relationship,
            role: person.UserRole
        }
    }
    else {
        return {
            id: person.id,
            name: person.fullName ?? (person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : undefined),
            email: person.emailAddress,
            employeePublicKey: person.employeePublicKey,
            relationship: person.relationship,
            role: person.userRole
        }
    }
}

const extraBundleAttributes = (bundle: Record<string, any>) => {
    const { BundlePublicKey, MetalLevel, ServiceAreaId, Price, Name, CarrierName, PlanType, HealthPlanId } = bundle
    return {
        id: BundlePublicKey,
        name: Name,
        metalLevel: MetalLevel,
        serviceAreaId: ServiceAreaId,
        carrierName: CarrierName,
        price: Price.toFixed(2),
        type: PlanType,
        HIOS: HealthPlanId
    }
}

const getBundleAttributesByHIOS = (HIOS: string) => {
    const { healthBundles } = storeInstance.getState()
    const attr = {
        HIOS
    }

    if (!healthBundles) {
        datadogRum.addError(new Error('Failed to load healthBundles redux store'))
        return attr
    }

    const { groups, currentGroupIndex } = healthBundles
    const bundleGroup = groups[currentGroupIndex];
    if (!bundleGroup || !bundleGroup.bundles) {
        datadogRum.addError(new Error('Failed to load specific bundle data from redux store'))
        return attr
    }

    const bundles = Object.values(bundleGroup.bundles) as any[]
    const match = bundles.filter(bundle => bundle.HealthPlanId === HIOS)[0]

    if (!match) {
        datadogRum.addError(new Error(`Failed to find specific bundle using HIOS ${HIOS} from redux store`))
        return attr
    }

    return extraBundleAttributes(match)
}

const getBundleAttributesByBundleId = (bundleId: string) => {
    const { healthBundles } = storeInstance.getState()
    const attr = {
        id: bundleId
    }

    if (!healthBundles) {
        datadogRum.addError(new Error('Failed to load healthBundles redux store'))
        return attr
    }

    const { groups, currentGroupIndex } = healthBundles
    const bundleGroup = groups[currentGroupIndex];
    if (!bundleGroup || !bundleGroup.bundles || !bundleGroup.bundles[bundleId]) {
        datadogRum.addError(new Error('Failed to load specific bundle data from redux store'))
        return attr
    }

    return extraBundleAttributes(bundleGroup.bundles[bundleId])
}

const getBenefitSummaryAttributes = (): Record<string, any> => {
    const { Cart } = storeInstance.getState()
    if (!Cart || !Cart.cart) {
        datadogRum.addError(new Error('Failed to load Cart redux store'))
        return {}
    }

    const benefits: any[] = Cart.cart.map(benefit => ({
        benefitType: benefit.BenefitType,
        declined: benefit.NotIncluded ? convertArrayIntoObjects(benefit.NotIncluded.map(dec => ({ personId: dec.Id, name: `${dec.FirstName} ${dec.LastName}` }))) : 'N/A',
        benefits: benefit.Benefits ? convertArrayIntoObjects(benefit.Benefits.map(ben => ({
            id: ben.BenefitPublicKey,
            bundleId: ben.BundlePublicKey,
            name: ben.PlanName,
            description: ben.Description,
            benefitEffectiveDate: ben.BenefitEffectiveDate,
            HIOS: ben.HealthPlanId,
            price: typeof ben.Price === 'number' ? ben.Price.toFixed(2) : ben.Price,
            fullPrice: typeof ben.FullPrice === 'number' ? ben.FullPrice.toFixed(2) : ben.FullPrice,
            carrierName: ben.CarrierName ?? ben.Carrier,
            groupNumber: ben.GroupNumber,
            carrierCSN: ben.CarrierCSN,
            employeeBenefitPublicKey: ben.EmployeeBenefitPublicKey,
            planType: ben.PlanType,
            metalLevel: ben.MetalLevel
        }))) : undefined
    }))

    // categorize and convert to object so it displays better in datadog
    const benefitAttr: Record<string, any> = {}
    benefits.forEach(bene => {
        if (benefitAttr[bene.benefitType]) {
            const keys = Object.keys(benefitAttr[bene.benefitType])
            benefitAttr[bene.benefitType][keys.length + 1] = bene
        } else {
            benefitAttr[bene.benefitType] = {
                0: bene
            }
        }
    })

    return benefitAttr;
}

const getBenefitActionAttributes = (benefitType: string, coveredPersons: any[], benefitProducts: any | any[]) => {
    const benefits = Array.isArray(benefitProducts) ? benefitProducts : [benefitProducts]
    return {
        actionTargetPersons: convertArrayIntoObjects(coveredPersons.map(extractPersonAttributes)),
        benefit: benefits.length > 0 && benefits[0] ? convertArrayIntoObjects(benefits.map(benefit => ({
            // TODO: This data structure is only tested for vision & dental
            // Other benefit types may need adjustment
            name: benefit.Name,
            planType: benefit.PlanType,
            price: typeof benefit.Price === 'number' ? benefit.Price.toFixed(2) : benefit.Price,
            fullPrice: typeof benefit.FullPrice === 'number' ? benefit.FullPrice.toFixed(2) : benefit.FullPrice,
            groupNumber: benefit.GroupNumber,
            carrierName: benefit.Carrier,
            carrierCSN: benefit.CarrierCSN,
            benefitPublicKey: benefit.BenefitPublicKey,
            description: benefit.Description,
            employeeBenefitPublicKey: benefit.EmployeeBenefitPublicKey,
            type: benefitType
        }))) : { type: benefitType }
    }
}

const getCommonAttributes = () => {
    const { userSession } = storeInstance.getState()

    const timestamp = {
        datetime: dayjs().format(DateTimeFormats.c),
        timezone: dayjs.tz.guess()
    }

    if (!userSession) {
        datadogRum.addError(new Error('Failed to load userSession redux store'))
        return {
            timestamp
        }
    }

    const { user, clientSettings, enrollmentSession } = userSession
    return {
        employee: extractPersonAttributes(user),
        enrollment: {
            id: enrollmentSession.Id,
            active: enrollmentSession.IsActive,
            cartStatus: enrollmentSession.CartStatus,
            cartCommited: enrollmentSession.CartCommitted,
            start: enrollmentSession.EnrollmentStartDate,
            end: enrollmentSession.EnrollmentEndDate,
            type: enrollmentSession.EnrollmentType
        },
        company: {
            id: clientSettings.Id,
            name: clientSettings.CompanyName,
            oep: {
                start: clientSettings.EnrollmentStartDate,
                end: clientSettings.EnrollmentEndDate
            },
        },
        timestamp
    }
}

const customAction = (name: typeof CustomActionNames[keyof typeof CustomActionNames], attributes: Record<string, any> = {}) => {
    const attrs = Object.assign({}, getCommonAttributes(), attributes)
    datadogRum.addAction(name, attrs)
}

const declineBenefits = (benefitType: string, coveredPersons: any[], benefitProducts: any | any[]) => {
    const attributes = getBenefitActionAttributes(benefitType, coveredPersons, benefitProducts)

    const actionNameMapper: Record<string, string> = {
        [BENEFIT_TYPE_ACCIDENT]: CustomActionNames.DeclineAccidental,
        [BENEFIT_TYPE_BASIC_LIFE]: CustomActionNames.DeclineBasicLife,
        [BENEFIT_TYPE_DENTAL]: CustomActionNames.DeclineDental,
        [BENEFIT_TYPE_VISION]: CustomActionNames.DeclineVision,
        [BENEFIT_TYPE_HOSPITAL_INDEMNITY]: CustomActionNames.DeclineHospitalIndemnity,
        [BENEFIT_TYPE_LONG_TERM_DISABILITY]: CustomActionNames.DeclineLongTermDisability,
        [BENEFIT_TYPE_HEALTH]: CustomActionNames.DeclineHealth,
        Medicare: CustomActionNames.DeclineMedicare,
    }
    const actionName = actionNameMapper[benefitType] ?? 'DECLINE_BENEFIT'
    customAction(actionName as any, attributes)
}

const addOrRemoveBenefitAction = (action: 'add' | 'remove', benefitType: string, coveredPersons: any[], benefitProducts: any | any[]) => {
    const attributes = getBenefitActionAttributes(benefitType, coveredPersons, benefitProducts)

    const actionNameMapper = {
        [BENEFIT_TYPE_ACCIDENT]: {
            add: CustomActionNames.AddAccidental,
            remove: CustomActionNames.RemoveAccidental,
        },
        [BENEFIT_TYPE_BASIC_LIFE]: {
            add: CustomActionNames.AddBasicLife,
            remove: CustomActionNames.RemoveBasicLife,
        },
        [BENEFIT_TYPE_DENTAL]: {
            add: CustomActionNames.AddDental,
            remove: CustomActionNames.RemoveDental,
        },
        [BENEFIT_TYPE_VISION]: {
            add: CustomActionNames.AddVision,
            remove: CustomActionNames.RemoveVision,
        },
        [BENEFIT_TYPE_HOSPITAL_INDEMNITY]: {
            add: CustomActionNames.AddHospitalIndemnity,
            remove: CustomActionNames.RemoveHospitalIndemnity,
        },
        [BENEFIT_TYPE_LONG_TERM_DISABILITY]: {
            add: CustomActionNames.AddLongTermDisability,
            remove: CustomActionNames.RemoveLongTermDisability,
        }
    } as const

    const actionName = actionNameMapper[benefitType]
    if (!actionName || !actionName[action]) {
        console.warn('datadog.addOrRemoveBenefitAction invalid benefitType or action')
        return
    }

    customAction(actionName[action], attributes)
}

/**
 * Start tracking S4H flow. When the duration of the flow can be calculated, the duration
 * will be returned, otherwise returns null.
 *
 * @param isQLE - true when in QLE S4H
 * @param end - true when marking the finish of the S4H flow
 * @returns number | null
 */
const trackS4HTiming = (isQLE: boolean, end = false): number | null => {
    const timingName = {
        start: isQLE ? 'QLE-S4H-start' : 'S4H-start',
        end: isQLE ? 'QLE-S4H-end' : 'S4H-end',
        duration: isQLE ? 'QLE-S4H-duration-' : 'S4H-duration-',
    }
    datadogRum.addTiming(end ? timingName.end : timingName.start);

    if (end) {
        const startTime = performance.getEntriesByName(timingName.start)[0]?.startTime ?? -1;
        const endTime = performance.getEntriesByName(timingName.end)[0]?.startTime ?? -1;

        if (startTime === -1 || endTime === -1) {
            // invalid start or end times - user did not land on S4H, and went directly
            // to finish and confirm step
            return null
        }

        const duration = endTime - startTime;
        datadogRum.addTiming(`${timingName.duration}${duration}`);

        return duration
    }
    return null
}

const addOrRemoveCompareHealth = (bundle, currentFaveHIOS: string[]) => {
    const action = currentFaveHIOS.indexOf(bundle.HealthPlanId) > -1 ? 'remove' : 'add'
    const actionName = action === 'add' ? CustomActionNames.AddHealthToCompare : CustomActionNames.RemoveHealthToCompare
    const comparisons = currentFaveHIOS.length > 0 ? convertArrayIntoObjects(currentFaveHIOS.map(hios => getBundleAttributesByHIOS(hios))) : undefined

    customAction(actionName, {
        target: extraBundleAttributes(bundle),
        comparisons
    })
}

const trackViewCompareBundles = () => {
    console.log('hellow???')
    const currentFaveBundles: any[] = getCurrentGroupFavoriteBundles(storeInstance.getState())
    const bundles = currentFaveBundles.length > 0 ? convertArrayIntoObjects(currentFaveBundles.map(extraBundleAttributes)) : 'No Plans Selected'

    customAction(CustomActionNames.ViewHealthComparison, {
        bundles
    })
}

export const datadog = {
    customAction,
    addOrRemoveBenefitAction,
    declineBenefits,
    trackS4HTiming,
    addOrRemoveCompareHealth,
    trackViewCompareBundles,
    extraBundleAttributes,
    extractPersonAttributes,
    getCommonAttributes,
    getBundleAttributesByBundleId,
    getBundleAttributesByHIOS,
    getBenefitSummaryAttributes,
    getBenefitActionAttributes,
    convertArrayIntoObjects,
    ...datadogRum
}
